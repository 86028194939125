import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <div className="font-mono text-9xl font-link">
              grace & class
          </div>
        <p className="text-sm mt-5">
          Coming Soon...
        </p>
      </header>
    </div>
  );
}

export default App;
